import axios, { type AxiosRequestConfig, type ResponseType } from 'axios'
import * as Sentry from '@sentry/react'

import AuthService from '@/apis/AuthService'

export interface ReturnDTO<T = any> {
  resultCode?: number
  resultMsg?: string
  resultData?: T
}

let isRefreshing = false
let failedQueue: {
  resolve: (value?: unknown) => void
  reject: (reaseon?: any) => void
}[] = []

const API = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
})

const processQueue = (error: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve()
    }
  })

  failedQueue = []
}

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error

    if (response?.status == 401 && !config.sent) {
      if (config.url == '/api/login') {
        return Promise.reject(response.data)
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then(() => API(config))
          .catch((error) => Promise.reject(error))
      }

      config.sent = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        setAuthorizationToken()

        const userSeqId = localStorage.getItem('userSeqId')
        const memberId = localStorage.getItem('memberId')
        const enrollType = localStorage.getItem('enrollType')

        if (!userSeqId && !memberId && !enrollType) {
          const err = '사용자 인증에 실패하여 로그아웃 됩니다.'
          processQueue(err)
          reject(err)

          isRefreshing = false
          return
        }

        AuthService.silentRefresh<ReturnDTO>()
          .then(({ resultCode, resultData }) => {
            if (resultCode === 0) {
              const newUserSeqId = resultData?.userSeqId
              const newMemberId = resultData?.memberId
              const newEnrollType = resultData?.enrollType

              if (
                newUserSeqId == userSeqId &&
                newMemberId == memberId &&
                newEnrollType == enrollType
              ) {
                const accessToken = resultData?.accessToken
                setAuthorizationToken(accessToken)
                config.headers.Authorization = `Bearer ${accessToken}`

                processQueue(null)
                resolve(API(config))
              } else {
                throw new Error('사용자 인증 식별 코드가 일치하지 않아 로그아웃 됩니다.')
              }
            } else {
              throw new Error('사용자 인증에 실패하여 로그아웃 됩니다.')
            }
          })
          .catch((err) => {
            Sentry.captureException(err, {
              extra: {
                url: err.config?.url,
                method: err.config?.method,
                data: err.config?.data,
                status: err.response?.status,
                responseData: err.response?.data,
              },
            })

            processQueue(err)
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    } else if (response?.data?.location) {
      window.location.href = response.data.location
    } else {
      Sentry.captureException(error, {
        extra: {
          url: config?.url,
          method: config?.method,
          data: config?.data,
          status: response?.status,
          responseData: response?.data,
        },
      })

      return Promise.reject(response?.data ?? error)
    }
  },
)

/**
 * Access token 헤더 설정.
 *
 * @param token Access token.
 */
function setAuthorizationToken(token?: string) {
  if (token) {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete API.defaults.headers.common['Authorization']
  }
}

/**
 * API GET 통신.
 *
 * @param urlPath API url.
 * @param params GET 타입의 인수들.
 * @param responseType 원하는 응답 데이터(JSON 혹은 Blob).
 * @param withCredentials For session.
 * @returns Promise<T>.
 */
async function httpGet<T>(
  urlPath: string,
  params?: { [key: string]: unknown },
  responseType?: ResponseType,
): Promise<T> {
  return API.get(urlPath, {
    params,
    responseType,
  }).then((res) => {
    return res?.data ?? res
  })
}

/**
 * API POST 통신.
 *
 * @param urlPath API url.
 * @param data 요청에 필요한 데이터 모음.
 * @param contentType Content-Type 설정.
 * @returns Promise<T>.
 */
async function httpPost<T>(
  urlPath: string,
  data?: unknown,
  contentType?: string,
): Promise<T> {
  const options: AxiosRequestConfig = {
    withCredentials: true,
  }
  if (contentType) {
    options.headers = {
      'Content-Type': contentType,
    }
  }

  return API.post(urlPath, data, options).then((res) => {
    return res?.data ?? res
  })
}

/**
 * API DELETE 통신.
 *
 * @param urlPath API url.
 * @param params DELETE 타입의 인수들.
 * @param responseType 원하는 응답 데이터(JSON 혹은 Blob).
 * @returns Promise<T>.
 */
async function httpDelete<T>(
  urlPath: string,
  params?: { [key: string]: unknown },
  responseType?: ResponseType,
): Promise<T> {
  return API.delete(urlPath, {
    params,
    responseType,
  }).then((res) => {
    return res?.data ?? res
  })
}

export { setAuthorizationToken, httpGet, httpPost, httpDelete }
